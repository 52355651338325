import React from "react"
import { graphql, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from "../components/layout"
import SEO from "../components/seo"
import TitleAnimation from '../components/title-animation'
import FeaturedProductsGrid from '../components/featured-products-grid'

import styles from '../css/index.module.css'

import Arrow from '../icons/arrow-back.svg'


const IndexPage = ({ data, location }) => {

    const schema = {
        "@context": "https://schema.org",
        "@type": "WebSite",
        "name": "Rare Threads",
        "@id": "https://shop.rarechicago.com/#threads",
        "url": "https://shop.rarechicago.com",
        "author": {
            "@type": "LocalBusiness",
            "name": "Rare Chicago",
            "description": "Chicago based Web Development and Digital Marketing Agency.",
            "@id": "https://rarechicago.com/#business",
            "url": "https://rarechicago.com",
            "logo": "https://rarechicago.com/schema_images/logo.png",
            "image": "https://rarechicago.com/schema_images/image.jpg",
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "",
                "addressLocality": "Chicago",
                "addressRegion": "IL",
                "postalCode": "60614",
                "addressCountry": "US"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "3129998388",
                "contactType": "customer service",
                "areaServed": "US",
                "availableLanguage": ["en","Chinese (Simplified)"]
            },
            "openingHoursSpecification": {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday"
                ],
                "opens": "09:00",
                "closes": "17:00"
            },
            "telephone": "3129998388",
            "email": "hello@rarechicago.com",
            "areaServed": [{
                "@type": "City",
                "name": ["Chicago"]
            }],
            "sameAs": [
                "https://www.facebook.com/rarechicagotech",
                "https://twitter.com/rarechicagotech",
                "https://www.instagram.com/rarechicagotech",
                "https://www.youtube.com/channel/UCjJjq6DX46hkQYqUx3ou0bA?fbclid=IwAR376_3na_j94bes0844YNSHiofU2b-1uTY-W3z8jiXfMx0gnb_2SoEkH1g",
                "https://www.linkedin.com/company/rarechicago",
                "https://github.com/rarechicago",
                "https://rarechicago.com"
            ]
        }
    }

    const socialCardImage = data.allFile.edges[0].node.childImageSharp.resize
    
    return(
        <Layout>
            <SEO
                title="Home"
                description="Rare Chicago presents Rare Threads. Artist inspired clothing store featuring hip graphics, coding humor, video game references, and pop culture plugs. Based in Chicago, made by artists around the world."
                image={socialCardImage}
                pathname={location.pathname}
                schema={schema}
            />
            <TitleAnimation />
            <div className="max-viewport mx-auto page-padding pt-20 laptop:pt-36 overflow-hidden">
                <div className={`${styles.introRow}`}>
                    <div className={`${styles.introBox} relative p-8 laptop:p-16`}>
                        <StaticImage
                            alt="Spaceship"
                            src="../images/spaceship.png"
                            className={`${styles.spaceship}`}
                            style={{ position: 'absolute' }}
                            width={250}
                            placeholder="none"
                        />
                        <StaticImage
                            alt="Spaceship"
                            src="../images/paint-splat.png"
                            className={`${styles.paintSplat}`}
                            style={{ position: 'absolute' }}
                            width={180}
                            placeholder="none"
                        />
                        <p className="mb-0">Rare Threads is a collection of art, culture, and humor that represent the things we love. We value our artists and the precious time they spend creating these beautiful pieces. Each purchase supports an individual artist.</p>
                    </div>
                </div>
                <h2>Our Featured Products</h2>
                <FeaturedProductsGrid />
                <div className="flex w-full justify-end">
                    <Link
                        to="/products/"
                        className="flex items-center"
                    >
                        <span>See All Products</span>
                        <img
                            alt="Arrow right icon"
                            src={Arrow}
                            className="arrow-right"
                        />
                    </Link>
                </div>
            </div>
        </Layout>
    )
}

export default IndexPage

export const pageQuery = graphql`
    query {
        allFile(filter: {name: {eq: "rarethreads-socialcard-home"}}) {
        edges {
            node {
            childImageSharp {
                resize(width: 1200) {
                src
                height
                width
                }
            }
            }
        }
        }
    }
`

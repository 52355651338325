import React, { useState, useContext } from 'react'
import StoreContext from '../context/StoreContext'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import styles from '../css/subscribe-bar.module.css'

import UFO from '../icons/ufo.svg'
import Chevron from '../icons/chevron-right.svg'
import Close from '../icons/close.svg'
import Blorg from '../icons/blorg.svg'
import AngryBlorg from '../icons/angry-blorg.svg'

const SubscribeBar = () => {

    const { notifySubscribed } = useContext(StoreContext);

    const [popUp, setPopUP] = useState(false);
    const [email, setEmail] = useState('');
    const [invalidEmail, setInvalidEmail] = useState(false);

    const handleClose = () => {
        setPopUP(false);
        setEmail('');
        setInvalidEmail(false);
    }

    const handleSubscribe = async (e) => {
        e.preventDefault();
        const res = await addToMailchimp(email);
        if (res.msg === "The email you entered is not valid.") {
            setInvalidEmail(true);
        } else {
            handleClose();
            notifySubscribed();
        }
    }

    return(
        <>
            <div className={styles.subscribeBar}>
                <div className="w-full h-full relative">
                    <button
                        className="flex w-full h-full items-center justify-around"
                        onClick={() => setPopUP(true)}
                    >
                        <div className="flex items-center">
                            <img
                                alt="Chevron right"
                                className='hidden tablet:block mr-2'
                                src={Chevron}
                            />
                            <p className="text-black font-bold mb-0">Enter to win a shirt</p>
                        </div>
                        <img
                            alt="UFO"
                            className={styles.ufo}
                            src={UFO}
                        />
                    </button>
                </div>
            </div>
            <div className={`${styles.popUp} ${popUp === true && styles.popUpActive}`}>
                <div className="bg-seagreen w-full rounded-xl relative" style={{ maxWidth: '400px' }}>
                    { invalidEmail === true ?
                        <img
                            alt="Blorg the alien"
                            className={styles.blorg}
                            src={AngryBlorg}
                        /> :
                        <img
                            alt="Blorg the alien"
                            className={styles.blorg}
                            src={Blorg}
                        />
                    }
                    <div className="w-full flex justify-end mb-2">
                        <button
                            className="mr-2 mt-2"
                            onClick={handleClose}
                        >
                            <img
                                alt="close button"
                                src={Close}
                                className="opacity-50"
                            />
                        </button>
                    </div>
                    <p className="text-dkblue text-center font-bold text-lg mb-2">Give me your email.</p>
                    <hr className="leading-none border-dkblue w-48 mx-auto border-opacity-20 mb-2"/>
                    <p className="text-center text-dkblue font-bold text-sm opacity-70">Your loyalty will be rewarded with<br/> a chance to win a free t-shirt.</p>
                    <form
                        id="subscribe-form"
                        className="relative"
                        onSubmit={e => handleSubscribe(e)}
                    >
                        <span
                            className={`${styles.invalidMessage} ${invalidEmail === true && styles.displayInvalid}`}
                        >
                            Email Invalid. Try again.
                        </span>
                        <input
                            id="email"
                            className={styles.input}
                            aria-label="email address"
                            type="text"
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                        />
                        <button
                            type="submit"
                            form="subscribe-form"
                            className={styles.submit}
                        >
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default SubscribeBar;